import { canUseDOM } from 'exenv';

interface DeviceInfo {
  /**
   * Whether the current device is a Mac.
   */
  isMac: boolean;
  /**
   * Whether the current device is a mobile.
   */
  isMobile: boolean;
}

/**
 * Hook to provide information about the current device.
 *
 * @author Adam Ayres
 */
export default function useDevice(): DeviceInfo {
  if (!canUseDOM) {
    return { isMac: false, isMobile: false };
  }

  const isMac = window.navigator.platform.includes('Mac');

  // Partial Copied from use-mobile-detect-hook/blob/master/src/index.ts
  const { userAgent } = window.navigator;
  const isAndroid = (): boolean => Boolean(userAgent?.match(/android/i));
  const isIos = (): boolean => Boolean(userAgent?.match(/iphone|ipad|ipod/i));
  const isOpera = (): boolean => Boolean(userAgent?.match(/opera mini/i));
  const isWindows = (): boolean => Boolean(userAgent?.match(/iemobile/i));

  const isMobile = isAndroid() || isIos() || isOpera() || isWindows();

  return {
    isMac,
    isMobile
  };
}
